import { RemoteWorkPolicyEnumLabels } from '@headrace/types';
import type { OptionsProps } from '@headrace/ui';
import {
  Input,
  MultiSelectField,
  NumberInput,
  Select,
  TextArea,
} from '@headrace/ui';
import type { VFC } from 'react';

import type { EnrichmentOptions } from '@/lib/hooks/useRoleEnrichmentOptions';

interface InputsFormCreateAndEditRoleProps {
  options: EnrichmentOptions | null;
  optionsEmployee?: OptionsProps[];
  isEdit: boolean;
  isLightCreation: boolean;
  importRole?: boolean;
}

const InputsFormCreateAndEditRole: VFC<InputsFormCreateAndEditRoleProps> = ({
  options,
  optionsEmployee,
  isEdit,
  isLightCreation,
  importRole,
}) => {
  if (options) {
    const remoteWorkPolicyOptions = Object.entries(
      RemoteWorkPolicyEnumLabels
    ).map(([key, val]) => ({
      label: val,
      value: key,
    }));
    return (
      <>
        {!importRole && (
          <div className="sm:col-span-6">
            <Input type="text" name="name" label="Role" />
          </div>
        )}
        {isEdit && optionsEmployee && (
          <div className="sm:col-span-6">
            <Select
              name="hiringManager"
              options={optionsEmployee}
              placeholder="Select a manager"
              isSearchable
              label="Hiring manager"
            />
          </div>
        )}
        <div className="sm:col-span-6">
          <Input
            type="text"
            helper="http://"
            name="jobDescriptionLink"
            label="Job Description Link"
            placeholder="www.example.com"
          />
        </div>
        {!isLightCreation && (
          <div className="sm:col-span-6">
            <TextArea name="description" label="Description" />
          </div>
        )}
        <div className="sm:col-span-3">
          <Select
            name="roleSeniority"
            options={options.roleSeniorityOptions}
            placeholder="Select a Seniority"
            isSearchable
            label="Level"
          />
        </div>
        <div className="sm:col-span-3">
          <Select
            name="roleFunction"
            options={options.roleFunctionOptions}
            placeholder="Select a Function"
            isSearchable
            label="Function"
          />
        </div>
        <div className="sm:col-span-3">
          <MultiSelectField
            options={options.cityOptions}
            name="cities"
            placeholder="Select office locations"
            maxMenuHeight={130}
            label="Office locations"
            hideSelectedOptions={false}
            isSearchable
            displayCounter
          />
        </div>
        <div className="sm:col-span-3">
          <Select
            options={remoteWorkPolicyOptions}
            name="remoteWorkPolicy"
            placeholder="Select a remote work policy"
            maxMenuHeight={130}
            label="Remote work policy"
          />
        </div>
        <div className="sm:col-span-3">
          <NumberInput
            name="expectedFirstYearSalaryMin"
            helper="$"
            placeHolderBeforeCursor="$"
            thousandSeparator
            decimalScale={0}
            allowNegative={false}
            defaultValue={0}
            label="Expected first year base salary (Min)"
          />
        </div>
        <div className="sm:col-span-3">
          <NumberInput
            name="expectedFirstYearSalaryMax"
            helper="$"
            placeHolderBeforeCursor="$"
            thousandSeparator
            decimalScale={0}
            allowNegative={false}
            defaultValue={0}
            label="Expected first year base salary (Max)"
          />
        </div>
        <div className="sm:col-span-3">
          <NumberInput
            name="quantity"
            thousandSeparator
            decimalScale={0}
            allowNegative={false}
            label="Quantity"
          />
        </div>
        {!isLightCreation && (
          <div className="sm:col-span-3">
            <NumberInput
              name="expectedFirstYearShareCount"
              helper="#"
              placeHolderBeforeCursor="#"
              thousandSeparator
              decimalScale={0}
              allowNegative={false}
              defaultValue={0}
              label="Expected first year equity"
              allowLeadingZeros
            />
          </div>
        )}
      </>
    );
  }
  return null;
};

export default InputsFormCreateAndEditRole;
