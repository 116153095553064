import { RadioGroup } from '@headlessui/react';
import { RequestRoleType } from '@headrace/types';
import type { OptionsProps } from '@headrace/ui';
import {
  Input,
  MailIcon,
  PhoneInput,
  RadioButtonOption,
  Select,
} from '@headrace/ui';
import classNames from 'classnames';
import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { useEmployeesByEmployerQuery } from '@/graphql/generated';

interface Props {
  selectedMailingListsManager: RequestRoleType;
  setSelectedMailingListsManager: Dispatch<SetStateAction<RequestRoleType>>;
  isLightCreation: boolean;
}

const CreateRoleDetails: React.VFC<Props> = (props) => {
  const {
    selectedMailingListsManager,
    setSelectedMailingListsManager,
    isLightCreation,
  } = props;
  const [employees, setEmployees] = useState<OptionsProps[]>([]);
  const { data, error } = useEmployeesByEmployerQuery();

  useEffect(() => {
    if (data?.employeesByEmployer) {
      const employeesList = data.employeesByEmployer.map((employee) => {
        const employeeOptions: OptionsProps = {
          label: `${employee.user.firstName} ${employee.user.lastName}`,
          value: employee.id,
        };
        return employeeOptions;
      });
      setEmployees(employeesList);
    }
    if (error) {
      toast.error(error.message);
    }
  }, [data, error]);

  const getOptionSelected = (): React.ReactElement | null => {
    if (selectedMailingListsManager === RequestRoleType.SELECT_HIRING_MANAGER) {
      return (
        <div
          className={classNames('flex justify-center items-center', {
            '!justify-start': isLightCreation,
          })}
        >
          <div className={classNames('w-6/12', { 'pr-1': isLightCreation })}>
            <Select
              name="hiringManager"
              label="Hiring manager"
              options={employees}
              isSearchable
            />
          </div>
        </div>
      );
    }
    if (selectedMailingListsManager === RequestRoleType.INVITE_HIRING_MANAGER) {
      return (
        <div className="sm:grid sm:grid-cols-6 sm:items-start gap-4">
          <div className="sm:col-span-3">
            <Input type="text" name="firstName" label="First name" />
          </div>
          <div className="sm:col-span-3">
            <Input type="text" name="lastName" label="Last name" />
          </div>
          <div className="sm:col-span-3">
            <Input
              label="Email"
              name="email"
              helper={<MailIcon />}
              placeholder="you@example.com"
            />
          </div>
          {!isLightCreation && (
            <div className="sm:col-span-3">
              <PhoneInput
                label="Phone"
                name="phone"
                placeholder="+1 (555) 987-6543"
              />
            </div>
          )}
        </div>
      );
    }
    return null;
  };

  const handleChangeSelected = (value: RequestRoleType): void => {
    setSelectedMailingListsManager(value);
  };

  return (
    <div>
      <RadioGroup
        value={selectedMailingListsManager}
        onChange={handleChangeSelected}
        className="flex flex-col items-center h-fit"
      >
        <div
          className={classNames(
            'mb-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4',
            { 'w-full': isLightCreation }
          )}
        >
          <RadioButtonOption
            title="Select hiring manager"
            value={RequestRoleType.SELECT_HIRING_MANAGER}
          />
          <RadioButtonOption
            title="Invite hiring manager"
            value={RequestRoleType.INVITE_HIRING_MANAGER}
          />
        </div>
      </RadioGroup>
      <div>{getOptionSelected()}</div>
    </div>
  );
};

export default CreateRoleDetails;
