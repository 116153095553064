import type { RemoteWorkPolicyEnum } from '@headrace/types';
import { RequestRoleType } from '@headrace/types';
import type { OptionsProps } from '@headrace/ui';
import { Button, Modal, SubmitButton, toastMessage } from '@headrace/ui';
import { completeUrl, formatApolloError } from '@headrace/utils';
import * as Sentry from '@sentry/nextjs';
import { Form, Formik } from 'formik';
import router from 'next/router';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import InputsFormCreateAndEditRole from '@/components/Role/InputsFormCreateAndEditRole';
import {
  useCreateRoleMutation,
  useEmployeesByEmployerQuery,
} from '@/graphql/generated';
import useRoleEnrichmentOptions from '@/lib/hooks/useRoleEnrichmentOptions';
import { CreateRoleFormSchema } from '@/validations';

import CreateRoleDetails from './CreateRoleDetails';

interface CreateRoleModalProps {
  open: boolean;
  onClose: () => void;
}

const CreateRoleModal: React.VFC<CreateRoleModalProps> = (props) => {
  const { open, onClose } = props;
  const [employees, setEmployees] = useState<OptionsProps[]>([]);
  const [selectedMailingListsManager, setSelectedMailingListsManager] =
    useState<RequestRoleType>(RequestRoleType.SELECT_HIRING_MANAGER);
  const { data: employeesByEmployer, error } = useEmployeesByEmployerQuery();
  const { options } = useRoleEnrichmentOptions();
  const [createRole, { loading: confirmLoading }] = useCreateRoleMutation({
    onCompleted: async (data) => {
      toast.success(
        toastMessage({
          title: 'Success!',
          subtitle: 'Your role has been created.',
        }),
        { duration: 2000 }
      );
      onClose();
      if (typeof data.createRole === 'string') {
        await router.replace('/roles/[roleId]', `/roles/${data.createRole}`);
      } else {
        Sentry.captureMessage('Invalid createRole.id');
      }
    },
    onError: (err) => {
      toast.error(formatApolloError(err));
    },
  });

  useEffect(() => {
    if (employeesByEmployer?.employeesByEmployer) {
      const employeesList = employeesByEmployer.employeesByEmployer.map(
        (employee) => {
          const employeeOptions: OptionsProps = {
            label: `${employee.user.firstName} ${employee.user.lastName}`,
            value: employee.id,
          };
          return employeeOptions;
        }
      );
      setEmployees(employeesList);
    }
    if (error) {
      toast.error(error.message);
    }
  }, [employeesByEmployer, error]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Create role"
      className="sm:min-w-[85%] md:min-w-[625px] lg:min-w-[625px] xl:min-w-[625px] m-10"
    >
      <Formik
        initialValues={{
          name: '',
          roleSeniority: '',
          roleFunction: '',
          cities: [],
          remoteWorkPolicy: '',
          quantity: 1,
          description: '',
          expectedFirstYearSalaryMin: null,
          expectedFirstYearSalaryMax: null,
          jobDescriptionLink: '',
          hiringManager: '',
          firstName: '',
          lastName: '',
          email: '',
        }}
        onSubmit={async (values): Promise<void> => {
          await createRole({
            variables: {
              input: {
                name: values.name,
                roleSeniorityId: values.roleSeniority,
                roleFunctionId: values.roleFunction,
                citiesId: values.cities,
                remoteWorkPolicy:
                  values.remoteWorkPolicy as RemoteWorkPolicyEnum,
                quantity: values.quantity || 0,
                expectedFirstYearSalaryMin: values.expectedFirstYearSalaryMin,
                expectedFirstYearSalaryMax: values.expectedFirstYearSalaryMax,
                jobDescriptionLink: values.jobDescriptionLink
                  ? completeUrl(values.jobDescriptionLink)
                  : '',
                hiringManager: values.hiringManager || undefined,
                description: '',
                isLightCreation: true,
                firstNameHiringManager: values.firstName,
                lastNameHiringManager: values.lastName,
                emailHiringManager: values.email,
              },
            },
          });
        }}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        validationSchema={CreateRoleFormSchema(selectedMailingListsManager)}
      >
        {(): JSX.Element => (
          <Form>
            <div className="sm:grid sm:grid-cols-6 sm:items-start gap-4">
              <InputsFormCreateAndEditRole
                options={options}
                optionsEmployee={employees}
                isEdit={false}
                isLightCreation
              />
              <div className="sm:col-span-6 pt-4 border-t">
                <CreateRoleDetails
                  selectedMailingListsManager={selectedMailingListsManager}
                  setSelectedMailingListsManager={
                    setSelectedMailingListsManager
                  }
                  isLightCreation
                />
              </div>
              <div className="sm:col-span-3">
                <Button
                  onClick={onClose}
                  buttonType="secondary"
                  className="sm:w-full w-full"
                >
                  Cancel
                </Button>
              </div>
              <div className="sm:col-span-3">
                <SubmitButton
                  loading={confirmLoading}
                  disabled={confirmLoading}
                  className="sm:w-full w-full"
                >
                  Create
                </SubmitButton>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default CreateRoleModal;
